import { render, staticRenderFns } from "./MyVote.vue?vue&type=template&id=6500a5fe&scoped=true&"
import script from "./MyVote.vue?vue&type=script&lang=js&"
export * from "./MyVote.vue?vue&type=script&lang=js&"
import style0 from "./MyVote.vue?vue&type=style&index=0&id=6500a5fe&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6500a5fe",
  null
  
)

export default component.exports