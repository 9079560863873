<template>
  <div class="myVote">
    <Nav></Nav>
    <div class="myVoteCon">
       <div class="con0">
            <p><b>{{lang('wodezhiya')}}</b></p>
       </div>
       <div class="con1">     
        <div class="conNum">
          <div class="a">
            <p><b>{{myInfoObj.num}}</b></p>
            <p class="n">{{lang('pledgenodenum')}}</p>
          </div>
          <div class="a">
            <p><b>{{BigNumberStr(myInfoObj.totalAmount,18,3)}}</b></p>
            <p class="n">{{lang('pledgetotal')}}</p>
          </div>
           <div class="a">
            <p><b>{{BigNumberStr(myInfoObj.totalReward,18,3)}}</b></p>
            <p class="n">{{lang('leijizhiyahsouyi')}}</p>
          </div>
        </div>
      </div>
      <!-- <div class="pending">
        <div class="pendindL">
          {{lang('dailingquzhiyashouyi')}}
        </div>
        <div class="pendingR">
          <span>111</span>
          <span class="cursor-pointer"  >{{lang('clickreceive')}}</span>
        </div>
      </div> -->
      <div class="con2">
        <div class="conList">{{lang('toupiaoliebiao')}}</div>       
      </div>    
      <div class="zanwu" v-if="getList.length==0">{{lang('unPle')}}</div>
      <div class="con3" v-if="getList.length>0">
        <div class="cont" v-for="(item,index) in getList" :key="index">
            <div class="content" >
              <div class="jxsb" v-if="item.canDeposit==false">
                <img src="../../assets/img/jxsb.png" alt="">
              </div>
                <div class="top cursor-pointer" @click="jumNodeInfo(item.nodeAddr,item.name)">
                    <div class="top1"></div>
                    <div class="reactivePc"><div class="top2">{{cutNameLink(item.name)}}</div></div>
                    <div class="reactiveMob"> <div class="top2">{{cutNameH(item.name)}}</div></div>
                   
                </div>
                <div class="bot">
                    <div class="botL">{{lang('totalple')}}{{BigNumberStr(item.totalAmount,18)}}
                    </div>
                    <div class="botR" ></div>
                </div>
                <div class="mypledge">
                  <div class="mypledgeL">{{lang('myple')}}{{BigNumberStr(item.amount,18)}}</div>
                  <div class="mypledgeR">
                    <span class="cursor-pointer" @click="showVoteRede(item.nodeAddr,item.amount)">{{lang('a_shuhui')}}</span>
                  </div>
                </div>
                <div class="est">
                  <div class="estL" v-if="item.canDeposit===true">{{lang('estimatedday')}}{{BigNumberStr(item.reward,18,3)}}</div>
                  <div class="estL" v-if="item.canDeposit===false">{{lang('estimatedday')}} 0</div>
                  <div class="estR"></div>
                </div>
                <div class="rev">
                  <div class="revL">{{lang('pendingrevenue')}} : {{BigNumberStr(item.voteReward,18,3)}} </div>
                  <div class="revR">
                    <span class="cursor-pointer" @click="getReceive(item.nodeAddr,BigNumberStr(item.voteReward,18))">{{lang('receive')}}</span>
                  </div>
                </div>
            </div>
            <div class="btnVote cursor-pointer" v-if="item.canDeposit===true" @click="showVote(item.nodeAddr,item.name,item.canDeposit)">{{lang('jixuzhiya')}}</div>
            <div class="btnVote cursor-pointer" style="opacity: 0.5" v-if="item.canDeposit===false">{{lang('jixuzhiya')}}</div>
        </div>
        <div class="list" v-for="item in (row-getList.length%row)" :key="item.id" ></div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "../../components/Nav/Index";
import { lang } from "../../tools";
import * as metaMaskTools from "../../tools/chain";
import { mixin1, mixin2, mixin3 } from "../../tools/mixins";
import { cutNameLink,cutNameH} from "../../tools/index";
import { busEvent } from '../../components/busEvent'
import { Toast } from 'vant';
export default {
  name: "myVote",
  components: { Nav },
  mixins: [mixin1, mixin2, mixin3],
  computed: {
    account() {
      return this.$store.state.accounts[0];
    },
  },
  mounted() {
  },
  created(){
    let this_ = this
    busEvent.$on('getBus',function(val){
      this_.getVoteList();
      this_.getMyVoteInfo()
    }),
    busEvent.$on('getBusRede',function(val){
      this_.getVoteList();
      this_.getMyVoteInfo()

    })
  },
  beforeDestroy() {
    //清除定时器
    clearTimeout(this.setTime);
    clearTimeout(this.setTimeOnly);
  },
  data() {
    return {
		  row: 4,
      setTime: null,
      setTimeOnly: null,
      active: 'a',
      myInfoObj:{
        num:0,
        totalAmount:0,
        totalReward:0
      },
      getList:[],
      names:"",
      address:""
    };
  },
  methods: {
    cutNameLink(name){
        return cutNameLink(name);
    },
    cutNameH(name) {
        return cutNameH(name);
    },
    initPage() {
      this.getMyVoteInfo()
      this.getVoteList()
    },
    initData() {
    },
    showVoteRede(nodeAddress,amount){
        this.$store.commit('setState', {
          voteRedeShow: true,
          selectVoteRede:nodeAddress,
          selectVoteRedeNum:amount,
          
        })
      },
    showVote(address,name,flag){
      console.log(flag,'flag');
        this.$store.commit('setState', {
          voteShow: true,
          selectItem:address,
          selectName:name,
          selectVotecanDeposit:flag,
        })
      },
    jumNodeInfo(address,name) {
      // this.name=item.name
      let query = {
                projectId: address,
                projectIdNmae:name
            };
      // sessionStorage.setItem('address', this.address)
      this.$router.push({
        path: '/nodeList',
        query: query
        } ); 
    },
    onRefresh(accounts) {
        this.initPage()
    },
    async getReceive(address,balance) {
      if(balance<=0) {
        Toast(this.lang('nono'))
      } else {
        console.log(address);
        var res = await metaMaskTools.getReceive(address);
        this.getVoteList()
        Toast(this.lang('lingquchenggong'))
      }
      
    },
    async getMyVoteInfo() {
      var res = await metaMaskTools.getMyVoteInfo(this.account);
      this.myInfoObj=res
    },
    async getVoteList() {
      var res = await metaMaskTools.getVoteList(this.account);
      console.log(res,333);
      this.getList=res  
    },
    lang(key){
        return lang(key);
    },
  },
};
</script>

<style scoped lang="less">
.cursor-pointer{
  cursor: pointer;
}
@media (min-width: 769px) {
  .myVoteCon {
  box-sizing: border-box;
  width: 100%;
  padding: 110px 40px 0 240px;
  .con0 {
      z-index: 40;
    position: fixed;
    margin-top: 80px;
    width: 100%;
    p {
      font-size: 15px;
      color: #fff;
    }
   }
  .con1 {
     z-index: 40;
    margin-top: 140px;
    width: 100%;
    p {
      font-size: 15px;
      color: #fff;
    }
    .conNum {
      display: flex;
      align-items: center;
      border-radius: 10px;
      width: 100%;
      height: 80px;
      background: linear-gradient(#0077c5, #0045bb);
      margin-top: 20px;
      .a {
        text-align: center;
        width: 33.33%;
        border-right: 1px solid #ccc;
        .n {
          margin-top: 8px;
        }
      }
      .a:nth-of-type(3) {
          border-right: 0;
      }
    }
  }
  .con2 {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        width: 100%;
        color: #fff;
  }
  .pending {
      display: flex;
      justify-content: space-between;
      height: 51.5px;
      line-height: 51.5px;
      background-color: rgba(#0f111f, 0.4);
      border-radius: 5px;
      margin-top: 20px;
      color: #fff;
      padding: 0 10px;
      font-size: 12px;
      .pendingR {
        span:nth-of-type(2) {
          display: inline-block;
          padding: 0 10px;
          height: 22.5px;
          line-height: 21.5px;
          border: 1px solid #0077c5;
          text-align: center;
          margin-left: 20px;
          color: #0077c5;
          border-radius: 10px;
        }
      }
    }
  .zanwu {
    margin-top: 20px;
    color: rgba(#fff,.3);
    text-align: center;
  }
  .con3 {
      display: flex;
        flex-wrap: wrap;
        justify-content:space-between;
        justify-items: center;
        text-align: justify;
        margin-top: 20px;
      .cont {
          content: '';
          width: 21%;
          height: 260px;
          background-color: rgba(#0F111F,.5);
          border-radius: 10px;
          padding: 7.5px 7.5px 0;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          .content {
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 100%;
              height: 198px;
              background-color: rgba(#0F111F,.5);
              border-radius:10px ;
              padding: 29px 9.5px 21.5px 13px;
              font-size: 12px;
              color: #fff;
              .jxsb {
                position: absolute;
                top: -15px;
                left: -6.5px;
                img {
                  width: 80px;

                }
              }
              .top {
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;
                  margin-bottom: 5px;
                  .top1 {
                    width: 30px;
                    height: 30px;
                    background: url('../../assets/img/ytf.png') no-repeat;
                    background-size: 100% 100%;
                    margin: 0 auto 12px;
                  }
              }
              .bot,.mypledge,.est,.rev {
                  margin-top: 8px;
                  display: flex;
                  justify-content: space-between;
                  .revR,.mypledgeR span {
                      color: #0077C5;
                  }
              }
          }
          .btnVote {
              padding: 0 10px;
              height: 22.5px;
              line-height: 22.5px;
              text-align: center;
              background: linear-gradient(#0077c5, #0045bb);
              margin: 10px 0 7.5px;
              font-size: 12px;
              color: #fff;
              border-radius:10px ;
          }
      }
  }
  .list{
  content: '';
  width: 21%;
  overflow: hidden;
}
}
}

@media (max-width: 768px) {
  .myVoteCon {
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px 0 ;
   .con0 {
    margin-top: 15px;
    width: 100%;
    p {
      font-size: 15px;
      color: #fff;
    }
   }
   .con1 {
      margin-top: 20px;
      width: 100%;
      p {
        font-size: 12px;
        color: #fff;
      }
      .conNum {
        display: flex;
        align-items: center;
        border-radius: 10px;
        width: 100%;
        height: 80px;
        border-radius: 10px;
        background: linear-gradient(#0077c5, #0045bb);
        margin-top: 20px;
        .a {
          text-align: center;
          width: 33.33%;
          border-right: 1px solid #ccc;
          .n {
            margin-top: 8px;
          }
        }
        .a:nth-of-type(3) {
            border-right: 0;
        }
      }
    }
   .pending {
      display: flex;
      justify-content: space-between;
      height: 51.5px;
      line-height: 51.5px;
      background-color: rgba(#0f111f, 0.4);
      border-radius: 5px;
      margin-top: 20px;
      color: #fff;
      padding: 0 10px;
      font-size: 12px;
      .pendingR {
        span:nth-of-type(2) {
          display: inline-block;
          padding: 0 10px;
          height: 22.5px;
          line-height: 21.5px;
          border: 1px solid #0077c5;
          text-align: center;
          margin-left: 20px;
          color: #0077c5;
          border-radius: 10px;
        }
      }
    }
   .con2 {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: #fff;
        font-size: 14px;
  }
  .zanwu {
    margin-top: 20px;
    color: rgba(#fff,.3);
    text-align: center;
  }
  .con3 {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content:space-between;
        justify-items: center;
        text-align: justify;
        margin-top: 20px;
        width: 100%;
      .cont {
          content: '';
          width: 48%;
          height: 250px;
          background-color: rgba(#0F111F,.5);
          border-radius: 10px;
          padding: 7.5px 7.5px 0;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          .content {
            position: relative;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 100%;
              height: 188px;
              background-color: rgba(#0F111F,.5);
              border-radius:10px ;
              padding: 20px 9.5px 21.5px 13px;
              font-size: 12px;
              color: #fff;
              .jxsb {
                position: absolute;
                top: -0.15rem;
                left: -0.065rem;
                img {
                  width: 0.5rem;
                }
              }
              .top {   
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;
                  .top1 {
                    width: 30px;
                    height: 30px;
                    background: url('../../assets/img/ytf.png') no-repeat;
                    background-size: 100% 100%;
                    margin: 0 auto 12px;
                  }
              }
               .bot,.mypledge,.est,.rev {
                  margin-top: 8px;
                  display: flex;
                  justify-content: space-between;
                  .revR,.mypledgeR span {
                      color: #0077C5;
                  }
              }
          }
          
          .btnVote {
              width: 75px;
              height: 22.5px;
              line-height: 22.5px;
              text-align: center;
              background: linear-gradient(#0077c5, #0045bb);
              margin: 10px 0 5px;
              font-size: 12px;
              color: #fff;
              border-radius:10px ;
          }
      }
      .cont:last-of-type {
        margin-bottom: 20px;
      }
      
  }
  .list{
  content: '';
  width: 48%;
  overflow: hidden;
}
}
}
</style>
